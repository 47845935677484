#LeftPane {
  width: 245px;
  background-color: $cffffff;
  height: 100vh;
  overflow: auto;
  border-left: 0.5px solid $cE0E0E0;
  border-right: 0.5px solid $cE0E0E0;
  .copy {
    display: none;
  }

  .navigatorText {
    padding: 6px 0px 6px 16px;
    font-size: 11px;
    color: $selectButtonColor;
    opacity: 1;
    border-bottom: 1px solid $cE6E6E6;
  }

  .hover {
    .copy {
      display: block;
    }
  }

  .navigator {
    color: $c4F4F4F;
    border-bottom: 0.5px solid $cE0E0E0;
    padding: 6px 7px;
    display: flex;
    font-size: 14px;
    color: #7a869a;
    > div {
      cursor: pointer;
    }
    .active {
      color: $textfieldColor;
      font-weight: 500;
    }
  }

  .properties {
    color: $c4F4F4F;
    display: flex;
    background-color: $widgetNavBackgroundColor;
    position: sticky;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    z-index: 10;
    line-height: 16px;
    height: 42px;
    padding: 2px 2px;
    border-bottom: 0.5px solid $cE0E0E0;
    border-right: 0.5px solid $cE0E0E0;
    z-index: 1000;

    .properties-options {
      width: 135px;
      color: $unselectTextColor;
      height: 38px;
      text-align: center;
      background-color: $widgetNavBackgroundColor;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        background-color: $selectButtonColor;
        color: $cffffff;
      }
    }
  }

  .elements {
    font-size: 12px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: 0.1px;
    cursor: pointer;
    .body,
    .block {
      height: 32px;
      line-height: 16px;
      margin: 0px 1px;
      color: $blockColor;
      svg {
        fill: $blockColor;
      }
    }

    .body {
      padding: 8px 8px;
    }

    .block {
      padding: 8px 12px;
    }
  }

  .elements .block,
  .elements .body {
    &:hover {
      outline: 0.5px solid $editorThemeColor;
      // border-radius: 4px;
      margin: 0px 1px;
      color: $editorThemeColor;
    }
    &.focused {
      background-color: $editorThemeColor;
      color: $cffffff;
      // border-radius: 4px;
    }

    &.focused svg {
      fill: white !important;
    }
  }

  .elements .block:hover svg {
    fill: $editorThemeColor;
  }
  .elements .body:hover svg {
    fill: $editorThemeColor;
  }
}

#NavigationComp {
  .contentBox {
    display: flex;
    flex-wrap: wrap;
  }
  .singleBox {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    height: 92.5px;
    justify-content: center;
    align-items: center;
    padding: 16px;
    text-transform: capitalize;
    border: 0.5px solid $cE6E6E6;
    cursor: pointer;
  }

  .moreOptionBox {
    padding: 12px;
    width: 300px;
    left: 85px;
    position: absolute;
  }
}
