#viewDetailComponent{

    margin-top: 8px;
    margin-bottom: 8px;
    min-width: 115px;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 1000;
    .singleItem{
        padding: 4px 16px 4px 16px;
    }

    .singleItem:hover{
        background-color: $cF2F2F2;
    }

}