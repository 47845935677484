#customslider{  
    position: relative;

    .track{
    width: 100%;
    height: 5px;
    background-color: #c4c4c4;
    margin-top: -14px;
    }

    .singleDiv{
        padding: 0px;
    }

    .rail{
        background-color: #5890E7;
        height: 5px;
        width: 100%;;
        z-index: 3;
    }
    .thumb{
        margin-top: -12px;
        z-index: 3; 
    }

    .thumb2{
        margin-top: -20px;
        z-index: 3; 
    }


}