$sizeamount: (
  5,
  10,
  15,
  16,
  20,
  25,
  30,
  33,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100
); // Adjust this to include the pixel amounts you need.

@each $size in $sizeamount {
  .w-#{$size} {
    // width: #{$size}% !important;
    width: #{#{$size} + '%'} !important;
  }
}
