$sizeamount: (
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9
); // Adjust this to include the pixel amounts you need.

@each $size in $sizeamount {
    .fw-#{$size} {
        font-weight: calc(#{$size} * 100) !important;
    }
}

