$cAFAEAE : #AFAEAE;
$c333333 : #333333;
$cEB763C : #EB763C;
$cffffff : #FFFFFF;
$c000000 : #000000;
$c828282 : #828282;
$c4F4F4F : #4F4F4F;
$cE0E0E0 : #E0E0E0;
$c459B95 : #459B95;
$cE8F3F2 : #E8F3F2;
$cF2F2F2 : #F2F2F2;
$cF7F6F4 : #F7F6F4;
$cFDEFE8 : #FDEFE8;
$cF7F7F7 : #F7F7F7;
$c1AC4D1 : #1AC4D1;
$cFAFAFA : #FAFAFA;
$c222222 : #222222;
$cE4E4E4 : rgb(228, 228, 228);
$c808080 : #808080;
$cEFEDE9 : #EFEDE9;
$c717171 : #717171;
$cF6FDFE : #F6FDFE;
$c262626 : #262626;
$cB0B0B0 : #B0B0B0;
$cF6F6F7 : #F6F6F7;
$c1D3B56 : #1D3B56;
$c486381 : #486381;
$cE7404A : #E7404A;
$cFA7C49 : #FA7C49;
$cFF005C : #FF005C;
$cFFCCDE : #FFCCDE;
$cF6F6F9 : #F6F6F9;
$c3D0A65 : #3D0A65;
$c2F80ED : #2F80ED;
$c1367D8 : #1367D8;
$cF0F0F0 : #F0F0F0;
$cBDBDBD : #BDBDBD;
$c2D9CDB : #2D9CDB;
$cE6E6E6 : #E6E6E6;
$c2E3F52 : #2E3F52;
$c14142B : #14142B;
$cEFF0F6 : #EFF0F6;
$c6E7191 : #6E7191;
$c4DCC6C : '#4DCC6C';
$c47B005 : '#47B005';
$c191919 : '#191919';
$c062A32 : '#062A32';
$cFEA2F1 : '#FEA2F1';
$cF8FAFB : '#F8FAFB';
$c757C83 : '#757C83';
$cE8F1FD : '#E8F1FD';
$cEAEAF1 : '#EAEAF1';

$poppins : 'Poppins';



$Secondary                   : #2FD8E5;
$headline3                   : #1D3B56;
$subtitle                    : #1D3B56;
$placeholder                 : #486381;
$caption                     : #BDBDBD;
$automationHeaderColor       : #B8285C;
$loyaltyCardHeaderColor      : #E7404A;
$buttonBackgroundColor       : #2F80ED;
$punchPassesHeaderColor      : #F5CB39;
$subscriptionHeaderColor     : #FF824C;
$coupansHeaderColor          : #2AB570;
$eventsHeaderColor           : #9C54AA;
$scratchEventsHeaderColor    : #464CB7;
$customerEmailHeaderColor    : #FA7C49;
$redemptionHeaderColor       : #FF005C;
$addredemptionBoxColor       : #F6F6F9;
$referralCampaignHeaderColor : #3D0A65;
$buttonHoverColor            : #1367D8;
$buttonTextColor             : #FFFFFF;
$themeColor                  : #2F80ED;
$tableBackgroundColor        : #F6F6F9;
$questionColor               : #29303E;
$tableActionDropdownColor    : #A0A3BD;
$tableSearchBoxColor         : #EAEBF0;
$error                       : #f44336;
$unlimitedPlanColor          : #7A7FFE;
$unlimitedPlanColorHover     : #676DFE;
$deleteButtonColor           : #EB5757;
$textfieldColor              : #14142B;
$textFieldBorderColor        : #E3E2E2;
$caption2Color               : #A0A3BD;
$headingColor                : #4F4F4F;
$returnToProHover            : #4DCC6C;
$returnToPro                 : #5ED17A;
$paragraphInputColor         : #999999;
$activeColor                 : #7ED98C;
$selectGreenBackgroundColor  : #6FCF97;
$accordionBorderColor        : #E0E0E0;
$accordiansidebarValue       : #EBFFF3;
$redColorButton              : #EB5757;
$goldOrangeColor             : #FFA92E;

//widgetColors
$selectButtonColor           : #333333;
$widgetNavBackgroundColor    : #F3F3F3;
$unselectTextColor           : #999999;
$propertySectionBackground   : #F6F5F8;
$propertySectionTextColor    : #7A869A;
$textFieldBackground         : #EEEEEE;
$editorThemeColor            : #1A9CFC;
$blockColor                  : #666666;

