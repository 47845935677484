#RightPane {
  width: 295px;
  margin-left: auto;
  background-color: #fff;
  height: 100vh;
  overflow: auto;
  cursor: default;
  overflow-x: hidden;
  border-left: 1px solid $cE6E6E6;

  .customfield-block {
    border-radius: 4px;
    border: 0.5px solid $cE0E0E0;
    display: flex;
    height: 32px;
    padding: 6px;
    align-items: center;
    justify-content: space-between;
  }

  .properties {
    color: $c4F4F4F;
    // border-bottom: 0.5px solid $cE0E0E0;
    display: flex;
    background-color: $widgetNavBackgroundColor;
    padding: 2px;
    position: sticky;
    top: 0;
    height: 42px;
    left: 0;
    right: 0px;
    z-index: 10;
    width: 295px;
    border-bottom: 0.5px solid $cE0E0E0;
    border-left: 0.5px solid $cE6E6E6;
    z-index: 1000;

    .properties-options {
      width: 50%;
      height: 38px;
      text-align: center;
      background-color: $widgetNavBackgroundColor;
      border-radius: 4px;
      cursor: pointer;
      color: $unselectTextColor;
      padding: 2px;
      &.active {
        background-color: $selectButtonColor;
        color: $cffffff;
      }
    }
  }

  .topBarItems {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .property-section {
    display: flex;
    margin: 0px -16px;
    padding: 8px 16px;
    border-bottom: 1px solid $cE6E6E6;
    font-size: 13px;
    cursor: pointer;
    background-color: $textFieldBackground;
  }

  .selectItems {
    padding: 2px;
    border: 1px solid $cE6E6E6;
    border-radius: 4px;
  }

  .divider {
    margin: 16px -16px;
    height: 0.5px;
    background-color: $cE6E6E6;
  }

  .property-name-divider {
    margin: 0px -16px;
    height: 1px;
    background-color: $cE6E6E6;
  }

  .Tag {
    position: absolute;
    top: 12px;
    right: 8px;
    font-size: 12px;
    color: $unselectTextColor;
  }

  .dropdown-input-box {
    border: 0.5px solid $cE0E0E0;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    height: 32px;
    > div {
      overflow: hidden;
      height: 100%;
      padding-right: 10px;
    }
    > input {
      height: 100%;
    }
    &:hover {
      border-color: $themeColor;
    }
  }

  .sizeInputField::placeholder {
    opacity: 1;
    font-size: '12px';
    color: $unselectTextColor;
  }

  .sizeInputField {
    font-size: 12px;
  }

  .predefine-dropdown {
    background: #fff;
    border: 1px solid $cE0E0E0;
    border-radius: 4px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    transition: opacity ease 0.7s;
    position: absolute;
    right: 0;
    z-index: 100;
    > div {
      border-bottom: 0.5px solid $cE0E0E0;
      padding: 4px 8px;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
    }
    > div:last-child {
      border-bottom: none;
    }
    > div:hover,
    > div.selected {
      background-color: $cE6E6E6;
    }
  }

  #PickColor {
    .backgroundColorBox {
      padding: 4px;
      border-radius: 4px;
      border: 1px solid $cE6E6E6;
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: $widgetNavBackgroundColor;
    }
    .colorCircle {
      height: 24px;
      min-width: 24px;
      border-radius: 32px;
      border: 1px solid $cF0F0F0;
      margin-right: 6px;
      background-color: white;
    }
    .pallette {
      position: absolute;
      margin-top: 6px;
      margin-left: -50px;
      z-index: 100;
    }
  }

  #UploadImage {
    .upload-block {
      border: 1px dashed #dbdbdb;
      border-radius: 4px;
      padding: 4px 6px;
      text-align: center;
      height: 32px;
      > div {
        height: -webkit-fill-available;
        overflow: hidden;
      }
    }
  }

  #Fonts {
    .text-alignment,
    .text-style {
      > div {
        width: 100%;
        height: 32px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.selected {
          background: white;
          box-shadow: 0px 0px 4px rgba(134, 145, 157, 0.4);
          border-radius: 4px;
        }
      }
    }
  }

  #Margin,
  #Direction,
  #Align,
  #Justify {
    .horizontalAlignment {
      width: 75%;
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      border: 1px solid $cE6E6E6;
      border-radius: 4px;
      background-color: $widgetNavBackgroundColor;
      padding: 2px;
      height: 36px;
      > div {
        width: 100%;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.selected {
          background: white;
          box-shadow: 0px 0px 4px rgba(134, 145, 157, 0.4);
          border-radius: 4px;
          border-radius: 4px;
        }
      }
    }
  }

  #BackgroundProperties {
    .background-size-options {
      height: 36px;
      padding: 2px;
      border: 1px solid $cE6E6E6;
      > div {
        padding: 6px 8px;
        border-radius: 8px;
        font-size: 12px;
        color: $textfieldColor;
        cursor: pointer;
      }
      > div:hover {
        background-color: $cE6E6E6;
      }
      > div.active {
        background-color: $cffffff;
        box-shadow: 0px 0px 4px rgba(134, 145, 157, 0.4);
        color: $themeColor !important;
      }
    }
  }

  #Padding,
  #Margin {
    .indSpacing {
      position: relative;
      width: 19px;
      height: 19px;
      .top,
      .right,
      .bottom,
      .left {
        background-color: $cE0E0E0;
      }
      .top {
        position: absolute;
        width: 15px;
        height: 2px;
        top: -1;
        left: 2px;
      }

      .right {
        position: absolute;
        width: 2px;
        height: 15px;
        right: 0;
        top: 1px;
      }

      .bottom {
        position: absolute;
        width: 15px;
        height: 2px;
        bottom: 0;
        left: 2px;
      }

      .left {
        position: absolute;
        width: 2px;
        height: 15px;
        left: 0;
        top: 1px;
      }
      .active {
        background-color: $c828282;
      }
    }
  }
}
