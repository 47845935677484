#Email {
    padding: 12.67% 0 11.6% 0;
    .card{
        padding: 24px 40px;
        width:480px;
        margin:auto;
        height: 100%;
    }

    .headingH2{
        color:  $subtitle;
    }
    
    .subtitle1{
        color: $caption,
    }

    .numberInput{
        text-align: center;
        height: 120px;
        padding: 0px;
        font-size: 50px;
        color:  $subtitle;
        border: none;
        border-bottom: 5px solid $caption;
        width: 20%;
    }
    .inputFields{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .verifyButton{
        background-color: $Secondary;
        height: 52px;
        width: 100%;
        border-radius: 8px;
        padding: 12px, 16px, 12px, 16px;
        font-size: 14px;
    }

    .resendLogout{ 
        color: $Secondary;
    }

    //verification code css

      .code-label {
        margin-bottom: 16px;
      }
      .code-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .code-inputs input {
        text-align: center;
        height: 120px;
        padding: 0px;
        font-size: 50px;
        color:  $subtitle;
        border: none;
        border-bottom: 5px solid $caption;
        width: 20%;
      }
      .code-inputs input:focus {
        outline: none;
      }
      
}