#EditorPane,
#Preview {
  width: calc(100% - 543px);
  min-height: 100vh;
  margin: 0 auto;
  padding: 44px 8px 2px 8px;
  overflow: auto;
  overflow-y: scroll;
  background-color: $textFieldBackground;

  &.preview {
    .hover,
    .focused {
      outline: none !important;
    }
  }

  .body.dragover {
    transform: scale(1.05, 1.05);
  }

  .dragover {
    transform: scale(1.1, 1.1);
    outline: 2px solid $themeColor;
    outline-offset: -2px;
  }

  .body {
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #fff;
    // border: 1px solid $cE0E0E0;
    // text-align: center;
    // padding: 4px;
    // border-radius: 4px;
    // border: 2px solid transparent;
    overflow: auto;
    height: 100vh;
    &.hover,
    &.focused {
      // padding: 4px;
      // border-radius: 4px;
      // border: 2px solid $themeColor;
      outline: 2px solid $themeColor;
    }
    &.focused {
      cursor: auto;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    // max-width: 1120px;
    // margin: auto;
    // width: fit-content;
    height: fit-content;
    background-repeat: no-repeat;
    transition: all 0.1s ease;
    // padding: 4px;
    // border-radius: 4px;
    // border: 2px solid transparent !important;
    color: inherit;
    &.hover,
    &.focused {
      // padding: 4px;
      // border-radius: 4px;
      // border: 2px solid $themeColor !important;
      outline: 2px solid $themeColor;
      outline-offset: -2px;
    }
    &.focused {
      cursor: auto;
    }
  }

  .emptyBlock {
    width: 100%;
    height: 100px !important;
  }

  .element {
    white-space: pre-wrap;
    // padding: 4px;
    // border-radius: 4px;
    // border: 2px solid transparent !important;
    &.hover,
    &.focused {
      // padding: 4px;
      // border-radius: 4px;
      // border: 2px solid $themeColor !important;
      outline: 2px solid $themeColor;
      outline-offset: -2px;
    }
    &.focused {
      cursor: auto;
    }
  }

  .divider {
    width: 80%;
    height: 1px;
    background: #cacaca;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
    .stepWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      &:last-of-type {
        flex: 0;
      }
    }
    .stepCircle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 12px;
    }
    .checkIcon {
      width: 16px;
      height: 16px;
    }
    .connector {
      flex: 1;
      height: 2px;
      margin: 0 8px;
      transition: background-color 0.2s ease;
    }
    .stepLabel {
      position: absolute;
      top: 40px;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .stepContent {
    margin-top: 20px;
    width: 100%;
  }
}
