#TemplateDesign {
  background-color: $cF2F2F2;
  position: relative;
  width: 100%;

  .saveBtn {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
  }

  .backPage {
    position: fixed;
    top: 10px;
    left: 50%;
  }

  .widgetHeader {
    background-color: white;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 42px;
    border-bottom: 0.5px solid $cE0E0E0;
    z-index: 100;
  }

  .viewandDownloadButtons {
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
  }

  .widgetTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .editIcon {
    vertical-align: text-bottom;
  }

  .full-height {
    min-height: 100vh;
  }

  .element-referer {
    width: fit-content;
    background: $themeColor;
    color: white;
    padding: 4px 8px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    position: absolute;
    font-size: 10px;
  }
}
