#settingHeader{
    width: 100%;

    .headerItems{
        padding: 16px;
    }

    .brand{
        border: 0px;
    }
    
    @media(max-width: 767px){
        .headerItems{
            justify-content: flex-end;
        }
    }
}