#ActiveStatus {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin: auto;
    &.active{
        background: green;
    }
    &.inactive{
        background: red;
    }
}