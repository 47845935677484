#dashboard{
    .brandBox {
        cursor: pointer;
        width: 96%;
        margin: auto;
        height: 128px;
        box-shadow: 0px -4px 28px rgba(0, 0, 0, 0.08), 0px 70px 100px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
    }
    .brandBox:hover {
        background-color:$cFAFAFA;
    }
    .paragraphsm {
        text-align: center;
    }
    .brandInfo {
        text-align: center;
    }

    .current {
        width: 96%;
        margin: auto;
    }

    .progressBar {
        width: 50px;
        height: 50px;
        background-color: none !important;
        margin-top: 57px;
        margin-right: 16px;
    }
    .CircularProgressbar {
        background-color: none !important;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px !important;
    }
    .progress {
        background-color: none !important;
    }
    
}
:focus{
        outline: none;
}


.headerModal {
    width: 100%;
    height: 121px;
    border-bottom: 1px solid $cE0E0E0;
}

.MuiInputAdornment-filled {
    margin-top: 0px !important;
}

.modalbox {
    height: 250px;
    width: 250px;
    box-shadow: 0px 0px 30px rgba(204, 204, 204, 0.3);
}

.closeModal {
    position: fixed;
    top: 21px;
    right: 21px;
    cursor: pointer;
}

