// Variables
$primary-color: #1AC4D1;
$primary-dark: darken($primary-color, 10%);
$success-color: #28a745;
$success-dark: darken($success-color, 10%);
$danger-color: #dc3545;
$danger-dark: darken($danger-color, 10%);
$gray-color: #6c757d;
$gray-dark: darken($gray-color, 10%);
$light-gray: #f8f9fa;
$border-color: #e0e0e0;
$text-color: #333333;
$text-muted: #6c757d;
$white: #ffffff;
$box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
$transition: all 0.2s ease-in-out;

// Mixins
@mixin button($bg-color, $hover-color) {
  background-color: $bg-color;
  color: $white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: $transition;
  
  &:hover {
    background-color: $hover-color;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($bg-color, 0.3);
  }
}

// Main Container
.data-table-container {
  width: 100%;
  min-height: 100vh;
  background-color: $light-gray;
  padding: 30px;
  display: flex;
  justify-content: center;
  
  .data-table-content {
    width: 100%;
    max-width: 1200px;
  }
  
  // Header Section
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    
    .page-title {
      font-size: 24px;
      font-weight: 600;
      color: $text-color;
      margin: 0;
    }
    
    .create-btn {
      @include button($primary-color, $primary-dark);
      display: flex;
      align-items: center;
      
      .btn-icon {
        margin-right: 8px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  
  // Table Container
  .table-container {
    background-color: $white;
    border-radius: 8px;
    box-shadow: $box-shadow;
    overflow: hidden;
    
    // Loading State
    .loading-state {
      padding: 40px;
      text-align: center;
      
      .spinner {
        width: 40px;
        height: 40px;
        border: 3px solid rgba($primary-color, 0.3);
        border-radius: 50%;
        border-top-color: $primary-color;
        animation: spin 1s ease-in-out infinite;
        margin: 0 auto 16px;
      }
      
      @keyframes spin {
        to { transform: rotate(360deg); }
      }
      
      p {
        color: $text-muted;
        font-size: 16px;
      }
    }
    
    // Empty State
    .empty-state {
      padding: 40px;
      text-align: center;
      
      p {
        color: $text-muted;
        font-size: 16px;
      }
    }
    
    // Data Table
    .data-table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      
      th, td {
        padding: 16px 20px;
        text-align: left;
      }
      
      th {
        background-color: #f1f3f5;
        color: $text-color;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: 1px solid $border-color;
        position: sticky;
        top: 0;
        
        &:first-child {
          width: 40%;
        }
        
        &:nth-child(2) {
          width: 40%;
        }
        
        &.actions-column {
          width: 20%;
          text-align: center;
        }
      }
      
      tbody tr {
        border-bottom: 1px solid $border-color;
        transition: $transition;
        
        &:hover {
          background-color: rgba($light-gray, 0.5);
        }
        
        &:last-child {
          border-bottom: none;
        }
      }
      
      td {
        color: $text-color;
        font-size: 14px;
        
        &.id-cell {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: monospace;
          color: $text-muted;
        }
        
        &.name-cell {
          font-weight: 500;
        }
        
        &.actions-cell {
          text-align: center;
        }
      }
      
      .action-buttons {
        display: flex;
        justify-content: center;
        gap: 10px;
        
        .edit-btn {
          @include button($success-color, $success-dark);
          padding: 6px 12px;
        }
        
        .delete-btn {
          @include button($danger-color, $danger-dark);
          padding: 6px 12px;
        }
      }
    }
  }
  
  // Modal
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(2px);
  }
  
  .modal-content {
    background-color: $white;
    border-radius: 8px;
    width: 450px;
    max-width: 90%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    animation: modalFadeIn 0.3s ease-out;
    
    @keyframes modalFadeIn {
      from { opacity: 0; transform: translateY(-20px); }
      to { opacity: 1; transform: translateY(0); }
    }
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $border-color;
    
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
    }
    
    .modal-close-btn {
      background: none;
      border: none;
      font-size: 24px;
      color: $text-muted;
      cursor: pointer;
      transition: $transition;
      
      &:hover {
        color: $danger-color;
      }
    }
  }
  
  .modal-body {
    padding: 20px;
    
    .form-group {
      margin-bottom: 16px;
      
      label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;
      }
      
      .name-input {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: 14px;
        color: $text-color;
        transition: $transition;
        
        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 3px rgba($primary-color, 0.2);
        }
        
        &::placeholder {
          color: lighten($text-muted, 15%);
        }
      }
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 20px;
    border-top: 1px solid $border-color;
    
    .cancel-btn {
      @include button($gray-color, $gray-dark);
    }
    
    .save-btn {
      @include button($primary-color, $primary-dark);
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .data-table-container {
    padding: 16px;
    
    .header-section {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    
    .data-table {
      th, td {
        padding: 12px;
      }
      
      .action-buttons {
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

@media (max-width: 576px) {
  .data-table-container {
    .data-table {
      th:first-child, td:first-child {
        display: none;
      }
      
      th:nth-child(2), td:nth-child(2) {
        width: 60%;
      }
      
      th.actions-column, td.actions-cell {
        width: 40%;
      }
    }
  }
}

