.bg-gradient {
    background: rgb(238, 174, 202) !important;
    background: radial-gradient(circle, rgba(238, 174, 202, 0.7035189075630253) 0%, rgba(148, 187, 233, 0.2721463585434174) 100%) !important;
}

/* General Flex and Layout Classes */
.flex {
    display: flex;
}
.w-full {
    width: 100%;
}
.flex-col {
    flex-direction: column;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.gap-5 {
    gap: 1.25rem; /* Adjust the spacing as needed */
}

/* Height */
.h-100vh {
    height: 100vh;
}

/* Backgrounds and Colors */
.bg-dark {
    background-color: #333; /* Set your desired dark background color */
}

.bg-danger {
    background-color: #e53e3e; /* Danger red color, adjust as needed */
}
.text-danger {
    color: #e53e3e;
}

/* Logo Styling */
.logo-width-150 {
    width: 150px;
}
.logo-width-300 {
    width: 300px;
}

/* Text Styling */
.text-4xl {
    font-size: 2.25rem; /* Adjust font size as needed */
}
.font-black {
    font-weight: 900;
}
.text-center {
    text-align: center;
}
.uppercase {
    text-transform: uppercase;
}
.text-lg {
    font-size: 1.125rem; /* Adjust font size as needed */
}
.font-semibold {
    font-weight: 600;
}
.capitalize {
    text-transform: capitalize;
}

/* Drop Shadow */
.drop-shadow-lg {
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust shadow size and color as needed */
}

/* Button Styling */
.button-base {
    color: #fff;
    padding: 0.5rem 1.25rem;
    border-radius: 0.375rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adjust shadow size and color as needed */
}

button {
    border: none;
}

.bg-danger-button {
    background-color: #e53e3e; /* Same as .bg-danger color */
}
.bg-primary-button {
    background-color: #a062e2 !important;
}
.ml-2 {
    margin-left: 0.5rem; /* Adjust margin as needed */
}
